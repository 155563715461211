import { useImmer } from "use-immer";
import { useEffect } from "react";
import Note from "../Note";
import WeightConfig from "./WeightConfig";
import TresholdConfig from "./TresholdConfig";
import AutoAllowRuleConfig from "./AutoAllowRuleConfig";
import CardAgeRuleConfig from "./CardAgeRuleConfig";
import CardLockAutoAllowRuleConfig from "./CardLockAutoAllowRuleConfig";
import CountryRuleConfig from "./CountryRuleConfig";
import CustomerCountryHistoryRuleConfig from "./CustomerCountryHistoryRuleConfig";
import CustomerFacilityHistoryRuleConfig from "./CustomerFacilityHistoryRuleConfig";
import CustomerHistoryRuleConfig from "./CustomerHistoryRuleConfig";
import CustomerOriginRuleConfig from "./CustomerOriginRuleConfig";
import DaytimeRuleConfig from "./DaytimeRuleConfig";
import FacilityRuleConfig from "./FacilityRuleConfig";
import HotspotRuleConfig from "./HotspotRuleConfig";
import OdometerHistoryRuleConfig from "./OdometerHistoryRuleConfig";
import OdometerIncrementHistoryRuleConfig from "./OdometerIncrementHistoryRuleConfig";
import OdometerLengthHistoryRuleConfig from "./OdometerLengthHistoryRuleConfig";
import OdometerRuleConfig from "./OdometerRuleConfig";
import RecentHotspotRuleConfig from "./RecentHotspotRuleConfig";
import RejectHistoryRuleConfig from "./RejectHistoryRuleConfig";
import SimilarOdometerRuleConfig from "./SimilarOdometerRuleConfig";
import SuddenHotspotVisitRuleConfig from "./SuddenHotspotVisitRuleConfig";
import SuspiciousPassageRuleConfig from "./SuspiciousPassageRuleConfig";
import VelocityRuleConfig from "./VelocityRuleConfig";
import VolumeHistoryRuleConfig from "./VolumeHistoryRuleConfig";
import VolumeRuleConfig from "./VolumeRuleConfig";
import AuthRejectConfig from "./AuthRejectRuleConfig";
import SuddenHotspotRejectRuleConfig from "./SuddenHotspotRejectRuleConfig";

const Component = ({ config, generalConfig, onConfigChange }) => {
  const [data, updateData] = useImmer(config);
  const [generalConfigData, updateGeneralConfigData] = useImmer(generalConfig);

  useEffect(() => {
    onConfigChange(data);
  }, [data]);

  useEffect(() => {
    updateData(config);
    updateGeneralConfigData(generalConfig);
  }, [config]);

  return (
    <>
      <h3>
        Weights{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.weight"
          noteTargetId={data.id}
        />
      </h3>
      <WeightConfig
        config={data.riskEvaluation.weightConfig}
        ruleSuppressGeneralConfig={
          generalConfigData.generalConfig.ruleSuppressConfig
        }
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.weightConfig = e;
          })
        }
      />
      <h3>
        Treshold{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.treshold"
          noteTargetId={data.id}
        />
      </h3>
      <TresholdConfig
        config={data.riskEvaluation.tresholdConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.tresholdConfig = e;
          })
        }
      />
      <h3>
        Auto allow rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.autoAllowRule"
          noteTargetId={data.id}
        />
      </h3>
      <AutoAllowRuleConfig
        config={data.riskEvaluation.autoAllowRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.autoAllowRuleConfig = e;
          })
        }
      />
      <h3>
        Card age rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.cardAgeRule"
          noteTargetId={data.id}
        />
      </h3>
      <CardAgeRuleConfig
        config={data.riskEvaluation.cardAgeRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.cardAgeRuleConfig = e;
          })
        }
      />
      <h3>
        Card lock auto allow rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.cardLockAutoAllowRule"
          noteTargetId={data.id}
        />
      </h3>
      <CardLockAutoAllowRuleConfig
        config={data.riskEvaluation.cardLockAutoAllowRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.cardLockAutoAllowRuleConfig = e;
          })
        }
      />
      <h3>
        Country rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.countryRule"
          noteTargetId={data.id}
        />
      </h3>
      <CountryRuleConfig
        config={data.riskEvaluation.countryRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.countryRuleConfig = e;
          })
        }
      />
      <h3>
        Customer country history rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.customerCountryHistoryRule"
          noteTargetId={data.id}
        />
      </h3>
      <CustomerCountryHistoryRuleConfig
        config={data.riskEvaluation.customerCountryHistoryRuleConfig}
        customerHistoryConfig={data.riskEvaluation.customerHistoryRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.customerCountryHistoryRuleConfig = e;
          })
        }
      />
      <h3>
        Customer facility history rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.customerFacilityHistoryRule"
          noteTargetId={data.id}
        />
      </h3>
      <CustomerFacilityHistoryRuleConfig
        customerHistoryConfig={data.riskEvaluation.customerHistoryRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.customerCountryHistoryRuleConfig = e;
          })
        }
      />
      <h3>
        Customer history rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.customerHistoryRule"
          noteTargetId={data.id}
        />
      </h3>
      <CustomerHistoryRuleConfig
        config={data.riskEvaluation.customerHistoryRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.customerHistoryRuleConfig = e;
          })
        }
      />
      <h3>
        Customer origin rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.customerOriginRule"
          noteTargetId={data.id}
        />
      </h3>
      <CustomerOriginRuleConfig
        config={data.riskEvaluation.customerOriginRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.customerOriginRuleConfig = e;
          })
        }
      />
      <h3>
        Daytime rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.daytimeRule"
          noteTargetId={data.id}
        />
      </h3>
      <DaytimeRuleConfig
        config={data.riskEvaluation.daytimeRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.daytimeRuleConfig = e;
          })
        }
      />
      <h3>
        Facility rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.facilityRule"
          noteTargetId={data.id}
        />
      </h3>
      <FacilityRuleConfig
        config={data.riskEvaluation.facilityRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.facilityRuleConfig = e;
          })
        }
      />
      <h3>
        Hotspot rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.hotspotRule"
          noteTargetId={data.id}
        />
      </h3>
      <HotspotRuleConfig
        config={data.riskEvaluation.hotspotRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.hotspotRuleConfig = e;
          })
        }
      />
      <h3>
        Odometer history rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.odometerHistoryRule"
          noteTargetId={data.id}
        />
      </h3>
      <OdometerHistoryRuleConfig
        config={data.riskEvaluation.odometerHistoryRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.odometerHistoryRuleConfig = e;
          })
        }
      />
      <h3>
        Odometer increment history rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.odometerIncrementHistoryRule"
          noteTargetId={data.id}
        />
      </h3>
      <OdometerIncrementHistoryRuleConfig
        config={data.riskEvaluation.odometerIncrementHistoryRuleConfig}
        odometerHistoryRuleConfig={
          data.riskEvaluation.odometerHistoryRuleConfig
        }
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.odometerIncrementHistoryRuleConfig = e;
          })
        }
      />
      <h3>
        Odometer length history rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.odometerLengthHistoryRule"
          noteTargetId={data.id}
        />
      </h3>
      <OdometerLengthHistoryRuleConfig
        config={data.riskEvaluation.odometerLengthHistoryRuleConfig}
        odometerHistoryRuleConfig={
          data.riskEvaluation.odometerHistoryRuleConfig
        }
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.odometerLengthHistoryRuleConfig = e;
          })
        }
      />
      <h3>
        Odometer rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.odometerRule"
          noteTargetId={data.id}
        />
      </h3>
      <OdometerRuleConfig
        config={data.riskEvaluation.odometerRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.odometerRuleConfig = e;
          })
        }
      />
      <h3>
        Recent hotspot rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.recentHotspotRule"
          noteTargetId={data.id}
        />
      </h3>
      <RecentHotspotRuleConfig
        config={data.riskEvaluation.recentHotspotRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.recentHotspotRuleConfig = e;
          })
        }
      />
      <h3>
        Reject history rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.rejectHistoryRule"
          noteTargetId={data.id}
        />
      </h3>
      <RejectHistoryRuleConfig
        config={data.riskEvaluation.rejectHistoryRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.rejectHistoryRuleConfig = e;
          })
        }
      />
      <h3>
        Similar odometer rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.similarOdometerRule"
          noteTargetId={data.id}
        />
      </h3>
      <SimilarOdometerRuleConfig
        config={data.riskEvaluation.similarOdometerRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.similarOdometerRuleConfig = e;
          })
        }
      />
      <h3>
        Sudden hotspot reject rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.suddenHotspotRejectRule"
          noteTargetId={data.id}
        />
      </h3>
      <SuddenHotspotRejectRuleConfig
        config={data.riskEvaluation.suddenHotspotRejectRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.suddenHotspotRejectRuleConfig = e;
          })
        }
      />
      <h3>
        Sudden hotspot visit rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.suddenHotspotVisitRule"
          noteTargetId={data.id}
        />
      </h3>
      <SuddenHotspotVisitRuleConfig
        config={data.riskEvaluation.suddenHotspotVisitRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.suddenHotspotVisitRuleConfig = e;
          })
        }
      />
      <h3>
        Suspicious passage rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.suspiciousPassageRule"
          noteTargetId={data.id}
        />
      </h3>
      <SuspiciousPassageRuleConfig
        config={data.riskEvaluation.suspiciousPassageRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.suspiciousPassageRuleConfig = e;
          })
        }
      />
      <h3>
        Velocity rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.velocityRule"
          noteTargetId={data.id}
        />
      </h3>
      <VelocityRuleConfig
        config={data.riskEvaluation.velocityRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.velocityRuleConfig = e;
          })
        }
      />
      <h3>
        Volume history rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.volumeHistoryRule"
          noteTargetId={data.id}
        />
      </h3>
      <VolumeHistoryRuleConfig
        config={data.riskEvaluation.volumeHistoryRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.volumeHistoryRuleConfig = e;
          })
        }
      />
      <h3>
        Volume rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.volumeRule"
          noteTargetId={data.id}
        />
      </h3>
      <VolumeRuleConfig
        config={data.riskEvaluation.volumeRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.volumeRuleConfig = e;
          })
        }
      />
      <h3>
        Any auth reject rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.anyAuthRejectRule"
          noteTargetId={data.id}
        />
      </h3>
      <AuthRejectConfig
        config={data.riskEvaluation.anyAuthRejectRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.anyAuthRejectRuleConfig = e;
          })
        }
      />
      <h3>
        Card deck auth reject rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.cardDeckAuthRejectRule"
          noteTargetId={data.id}
        />
      </h3>
      <AuthRejectConfig
        config={data.riskEvaluation.cardDeckAuthRejectRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.cardDeckAuthRejectRuleConfig = e;
          })
        }
      />
      <h3>
        Card lock auth reject rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.cardLockAuthRejectRule"
          noteTargetId={data.id}
        />
      </h3>
      <AuthRejectConfig
        config={data.riskEvaluation.cardLockAuthRejectRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.cardLockAuthRejectRuleConfig = e;
          })
        }
      />
      <h3>
        Fraud auth reject rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.fraudAuthRejectRule"
          noteTargetId={data.id}
        />
      </h3>
      <AuthRejectConfig
        config={data.riskEvaluation.fraudSuspicionAuthRejectRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.fraudSuspicionAuthRejectRuleConfig = e;
          })
        }
      />
      <h3>
        Fuel guard auth reject rule{" "}
        <Note
          className="float-right"
          noteTargetPath="rulesConfig.fuelGuardAuthRejectRule"
          noteTargetId={data.id}
        />
      </h3>
      <AuthRejectConfig
        config={data.riskEvaluation.fuelGuardAuthRejectRuleConfig}
        onChange={(e) =>
          updateData((d) => {
            d.riskEvaluation.fuelGuardAuthRejectRuleConfig = e;
          })
        }
      />
    </>
  );
};

export default Component;
