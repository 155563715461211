import AuthRejectConfig from "../component/configPage/AuthRejectRuleConfig";
import ApiConnector from "../connector/ApiConnector";
import CountryRuleConfig from "../component/configPage/CountryRuleConfig";
import CustomerHistoryRuleConfig from "../component/configPage/CustomerHistoryRuleConfig";
import DaytimeRuleConfig from "../component/configPage/DaytimeRuleConfig";
import HotspotRuleConfig from "../component/configPage/HotspotRuleConfig";
import OdometerRuleConfig from "../component/configPage/OdometerRuleConfig";
import TresholdConfig from "../component/configPage/TresholdConfig";
import VelocityRuleConfig from "../component/configPage/VelocityRuleConfig";
import VolumeRuleConfig from "../component/configPage/VolumeRuleConfig";
import WeightConfig from "../component/configPage/WeightConfig";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import RootConfig from "../component/RootConfig";
import CustomerOriginRuleConfig from "../component/configPage/CustomerOriginRuleConfig";
import CardAgeRuleConfig from "../component/configPage/CardAgeRuleConfig";
import Note from "../component/Note";
import { useHistory, useLocation } from "react-router-dom";
import LocationService from "../service/RouteUtilService";
import RouteUtilService from "../service/RouteUtilService";
import ConfigService from "../service/ConfigService";
import OdometerHistoryRuleConfig from "../component/configPage/OdometerHistoryRuleConfig";
import FacilityRuleConfig from "../component/configPage/FacilityRuleConfig";
import RejectHistoryRuleConfig from "../component/configPage/RejectHistoryRuleConfig";
import VolumeHistoryRuleConfig from "../component/configPage/VolumeHistoryRuleConfig";
import AutoAllowRuleConfig from "../component/configPage/AutoAllowRuleConfig";
import RecentHotspotRuleConfig from "../component/configPage/RecentHotspotRuleConfig";
import CustomerCountryHistoryRuleConfig from "../component/configPage/CustomerCountryHistoryRuleConfig";
import CustomerFacilityHistoryRuleConfig from "../component/configPage/CustomerFacilityHistoryRuleConfig";
import OdometerIncrementHistoryRuleConfig from "../component/configPage/OdometerIncrementHistoryRuleConfig";
import OdometerLengthHistoryRuleConfig from "../component/configPage/OdometerLengthHistoryRuleConfig";
import SimilarOdometerRuleConfig from "../component/configPage/SimilarOdometerRuleConfig";
import ConfigSelectorRuleConfig from "../component/configPage/ConfigSelectorRuleConfig";
import SuddenHotspotVisitRuleConfig from "../component/configPage/SuddenHotspotVisitRuleConfig";
import CardLockAutoAllowRuleConfig from "../component/configPage/CardLockAutoAllowRuleConfig";
import SuspiciousPassageRuleConfig from "../component/configPage/SuspiciousPassageRuleConfig";
import PosConfig from "../component/configPage/PosConfig";
import CustomerSuddenHotspotVisitEwPayRuleConfig from "../component/configPageEwPay/CustomerSuddenHotspotVisitEwPayRuleConfig";
import CustomerCardDeckEwPayRuleConfig from "../component/configPageEwPay/CustomerCardDeckEwPayRuleConfig";
import CustomerLimitEwPayRuleConfig from "../component/configPageEwPay/CustomerLimitEwPayRuleConfig";

const Component = (props) => {
  const [latestConfig, updateLatestConfig] = useImmer(null);
  const [data, updateData] = useImmer(null);
  const [generalConfigData, updateGeneralConfigData] = useImmer(null);
  const history = useHistory();
  const location = useLocation();
  const [selectedTab, updateSelectedTab] = useImmer("tabone");

  useEffect(async () => {
    const id = LocationService.getParam(location, "id");

    const isPageInitialized = latestConfig;
    if (!id || !isPageInitialized) {
      const config = await ApiConnector.getLatestConfig();
      updateLatestConfig(config);
      updateData(config);

      const generalConfig = await ApiConnector.getLatestGeneralConfig();
      updateGeneralConfigData(generalConfig);
    }

    if (id) {
      const config = await ApiConnector.getConfig(id);
      updateData(config);
    }
  }, [location]);

  const loadPreviousConfig = () => {
    let id = Number(LocationService.getParam(location, "id"));
    id = id ? id : latestConfig.id;
    let newLocation = LocationService.setParam(location, "id", id - 1);
    history.push(newLocation);
  };

  const loadNextConfig = () => {
    let id = Number(LocationService.getParam(location, "id"));
    if (!id) {
      console.warn(
        "Can't load next config because current config is the latest one.",
      );
      return;
    }
    let newLocation = LocationService.setParam(location, "id", id + 1);
    history.push(newLocation);
  };

  const createConfig = async () => {
    const newConfig = await ConfigService.createConfig(data, latestConfig.id);
    if (!newConfig) {
      return;
    }
    updateLatestConfig(newConfig);
    updateData(newConfig);
    history.push(RouteUtilService.deleteParam(location, "id"));
  };

  const isLastConfig = () => {
    return latestConfig.id === data.id;
  };

  return (
    <>
      <header className="subheader">
        <h1>Rules</h1>
      </header>
      <main>
        {data && generalConfigData && (
          <>
            <div className="row">
              <div className="col">
                <h2>Rules config</h2>
              </div>
              <div className="col-4">
                <h2 className="float-right">
                  <a
                    className="fa-solid fa-angle-left  "
                    onClick={(e) => loadPreviousConfig()}
                  />
                  &nbsp;&nbsp;
                  <a
                    className={
                      "fa-solid fa-angle-right " +
                      (isLastConfig() ? "gray" : "")
                    }
                    onClick={(e) => loadNextConfig()}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Note noteTargetPath="rulesConfig" noteTargetId={data.id} />
                  &nbsp;&nbsp;&nbsp;
                  <a
                    className="fa-regular fa-floppy-disk "
                    onClick={(e) => createConfig()}
                  />
                </h2>
              </div>
            </div>

            <RootConfig
              config={data}
              onIdSelected={(selectedId) => {
                let newLocation = LocationService.setParam(
                  location,
                  "id",
                  selectedId,
                );
                history.push(newLocation);
              }}
            ></RootConfig>

            <details>
              <summary>
                <h3>Ew Pay rules config</h3>
              </summary>
              <h3>
                Ew Pay Weights{" "}
                <Note
                  className="float-right"
                  noteTargetPath="rulesConfig.ewPayWeight"
                  noteTargetId={data.id}
                />
              </h3>
              <WeightConfig
                config={data.riskEvaluation.ewPayWeightConfig}
                ruleSuppressGeneralConfig={
                  generalConfigData.generalConfig.ruleSuppressConfig
                }
                onChange={(e) =>
                  updateData((d) => {
                    d.riskEvaluation.ewPayWeightConfig = e;
                  })
                }
              />

              <h3>
                Ew Pay Treshold{" "}
                <Note
                  className="float-right"
                  noteTargetPath="rulesConfig.ewPayTreshold"
                  noteTargetId={data.id}
                />
              </h3>
              <TresholdConfig
                config={data.riskEvaluation.ewPayTresholdConfig}
                onChange={(e) =>
                  updateData((d) => {
                    d.riskEvaluation.ewPayTresholdConfig = e;
                  })
                }
              />

              <h3>
                Customer card deck Ew Pay rule{" "}
                <Note
                  className="float-right"
                  noteTargetPath="rulesConfig.customerCardDeckEwPayRule"
                  noteTargetId={data.id}
                />
              </h3>
              <CustomerCardDeckEwPayRuleConfig
                config={data.riskEvaluation.customerCardDeckEwPayRuleConfig}
                onChange={(e) =>
                  updateData((d) => {
                    d.riskEvaluation.customerCardDeckEwPayRuleConfig = e;
                  })
                }
              ></CustomerCardDeckEwPayRuleConfig>

              <h3>
                Customer limit Ew Pay rule{" "}
                <Note
                  className="float-right"
                  noteTargetPath="rulesConfig.customerLimitEwPayRule"
                  noteTargetId={data.id}
                />
              </h3>
              <CustomerLimitEwPayRuleConfig
                config={data.riskEvaluation.customerLimitEwPayRuleConfig}
                onChange={(e) =>
                  updateData((d) => {
                    d.riskEvaluation.customerLimitEwPayRuleConfig = e;
                  })
                }
              ></CustomerLimitEwPayRuleConfig>

              <h3>
                Customer sudden hotspot visit Ew Pay rule{" "}
                <Note
                  className="float-right"
                  noteTargetPath="rulesConfig.ewPayWeight"
                  noteTargetId={data.id}
                />
              </h3>
              <CustomerSuddenHotspotVisitEwPayRuleConfig
                config={
                  data.riskEvaluation.customerSuddenHotspotVisitEwPayRuleConfig
                }
                onChange={(e) =>
                  updateData((d) => {
                    d.riskEvaluation.customerSuddenHotspotVisitEwPayRuleConfig =
                      e;
                  })
                }
              ></CustomerSuddenHotspotVisitEwPayRuleConfig>
            </details>
            <details open>
              <summary>
                <h3>Point of Sale rules config</h3>
              </summary>
              <PosConfig
                config={data}
                generalConfig={generalConfigData}
                onConfigChange={(e) => updateData(e)}
              ></PosConfig>
            </details>
          </>
        )}
      </main>
    </>
  );
};

export default Component;
