import { useImmer } from "use-immer";
import { useEffect } from "react";
import CustomerSuddenHotspotVisitEwPayStatusOption from "./CustomerSuddenHotspotVisitEwPayStatusOption";
import MerchantHotspotStatusOption from "../configPage/MerchantHotspotStatusOption";

const Component = ({ config, onChange }) => {
  const scoreTemplate = {
    isAllCustomersCards: true,
    historyLookupMinutesMin: -60,
    historyLookupMinutesMax: 0,
    countMin: 5,
    countMax: 999,
    scoreOnMatch: 10,
  };
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  const addScore = () => {
    updateData((draft) => {
      draft.scores.push(structuredClone(scoreTemplate));
    });
  };

  const removeScore = (index) => {
    updateData((draft) => {
      draft.scores.splice(index, 1);
    });
  };

  return (
    <>
      <table className="config-table-triple table-with-button">
        <thead>
          <tr>
            <th>
              History min. <small>[minutes]</small>
            </th>
            <th>
              History max. <small>[minutes]</small>
            </th>
            <th>Count all customers</th>
            <th>
              Ew Pay <br></br> count min.
            </th>
            <th>
              Ew Pay <br></br> count max.
            </th>
            <th>Score</th>
            <th>
              <a className="fa-solid fa-plus" onClick={addScore}></a>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.scores.map((score, index) => (
            <tr key={index}>
              <td>
                <input
                  type="number"
                  value={score.historyLookupMinutesMin}
                  step="1"
                  onChange={(e) =>
                    updateData((d) => {
                      let val = e.target.value;
                      val =
                        val < score.historyLookupMinutesMax
                          ? score.historyLookupMinutesMax
                          : val;
                      d.scores[index].historyLookupMinutesMin = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  value={score.historyLookupMinutesMax}
                  step="1"
                  onChange={(e) =>
                    updateData((d) => {
                      let val = e.target.value;
                      val =
                        val < score.historyLookupMinutesMin
                          ? score.historyLookupMinutesMin
                          : val;
                      d.scores[index].historyLookupMinutesMax = val;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={score.isAllCustomersCards}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scores[index].isAllCustomersCards =
                        !d.scores[index].isAllCustomersCards;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  value={score.countMin}
                  step="1"
                  onChange={(e) =>
                    updateData((d) => {
                      let val = e.target.value;
                      val = val > score.countMax ? score.countMax : val;
                      d.scores[index].countMin = val;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  value={score.countMax}
                  step="1"
                  onChange={(e) =>
                    updateData((d) => {
                      let val = e.target.value;
                      val = val < score.countMin ? score.countMin : val;
                      d.scores[index].countMax = val;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  value={score.scoreOnMatch}
                  step="1"
                  onChange={(e) =>
                    updateData((d) => {
                      let val = e.target.value;
                      val = val < 0 ? 0 : val;
                      d.scores[index].scoreOnMatch = val;
                    })
                  }
                />
              </td>
              <td>
                <a
                  className="fa-solid fa-trash-can"
                  onClick={() => removeScore(index)}
                ></a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Component;
